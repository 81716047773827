import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const IOSShare = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="m16 4-1.42 1.42-1.59-1.59V15h-1.98V3.83L9.42 5.42 8 4l4-4 4 4Zm4 5v11c0 1.1-.9 2-2 2H6a2 2 0 0 1-2-2V9c0-1.11.89-2 2-2h3v2H6v11h12V9h-3V7h3a2 2 0 0 1 2 2Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(IOSShare);
