import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const Disconnect = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 7c0-2.21-1.79-4-4-4S6 4.79 6 7s1.79 4 4 4 4-1.79 4-4ZM2 17v2h9.772l1.5-1.5-2.1-2.1 1.98-1.98C12.004 13.14 10.862 13 10 13c-2.67 0-8 1.34-8 4Zm15.4 2 .1-.1 2.1 2.1 1.4-1.4-2.1-2.1 2.1-2.1-1.4-1.4-2.1 2.1-1.774-1.775L15.4 14 14 15.4l2.1 2.1-2.1 2.1 1.4 1.4 2-2Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(Disconnect);
