import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const TikTok = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M20.032 10.555c-1.67 0-3.293-.542-4.544-1.458l-.01 6.232a5.676 5.676 0 1 1-4.922-5.632v3.06a2.68 2.68 0 1 0 1.92 2.572V3h3.149a4.406 4.406 0 0 0 4.406 4.407v3.148Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(TikTok);
