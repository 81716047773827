import React from 'react';
import Apple from './Apple';
import Facebook from './Facebook';
import Instagram from './Instagram';
import LinkedIn from './LinkedIn';
import Playstore from './Playstore';
import TikTok from './TikTok';
import Twitter from './Twitter';
import Windows from './Windows';
import YouTube from './YouTube';
import Account from './Account';
import AccountBalanceWallet from './AccountBalanceWallet';
import Add from './Add';
import AddAPhoto from './AddAPhoto';
import AddReaction from './AddReaction';
import Alarm from './Alarm';
import AndroidFlipCamera from './AndroidFlipCamera';
import AndroidMore from './AndroidMore';
import AndroidShare from './AndroidShare';
import ArrowDownward from './ArrowDownward';
import ArrowDropDown from './ArrowDropDown';
import ArrowDropUp from './ArrowDropUp';
import ArrowForward from './ArrowForward';
import ArrowUpward from './ArrowUpward';
import Assessment from './Assessment';
import Calendar from './Calendar';
import Campaign from './Campaign';
import Chat from './Chat';
import Check from './Check';
import CheckCircle from './CheckCircle';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import ChevronUp from './ChevronUp';
import Close from './Close';
import ClosedCaptionOff from './ClosedCaptionOff';
import ClosedCaptionOn from './ClosedCaptionOn';
import Connect from './Connect';
import ContentCopy from './ContentCopy';
import CreditCard from './CreditCard';
import Critical from './Critical';
import Dashboard from './Dashboard';
import Delete from './Delete';
import Disconnect from './Disconnect';
import Download from './Download';
import Edit from './Edit';
import Email from './Email';
import EndSession from './EndSession';
import EventSeat from './EventSeat';
import Explore from './Explore';
import ExploreFilled from './ExploreFilled';
import FastForward from './FastForward';
import FastRewind from './FastRewind';
import Favorite from './Favorite';
import FavoriteFilled from './FavoriteFilled';
import FilterList from './FilterList';
import Flag from './Flag';
import FormatIndentDecrease from './FormatIndentDecrease';
import FormatIndentIncrease from './FormatIndentIncrease';
import FormatListBulleted from './FormatListBulleted';
import FormatListNumbered from './FormatListNumbered';
import Forward10 from './Forward10';
import Forward30 from './Forward30';
import Forward5 from './Forward5';
import Fullscreen from './Fullscreen';
import FullscreenExit from './FullscreenExit';
import GroupRemove from './GroupRemove';
import HamburgerMenu from './HamburgerMenu';
import Help from './Help';
import IOSShare from './IOSShare';
import Image from './Image';
import Info from './Info';
import IosArrowBack from './IosArrowBack';
import IosArrowForward from './IosArrowForward';
import IosFlipCamera from './IosFlipCamera';
import IosMore from './IosMore';
import Language from './Language';
import Link from './Link';
import LinkOff from './LinkOff';
import Lock from './Lock';
import Logout from './Logout';
import Menu from './Menu';
import MenuOpen from './MenuOpen';
import MicOff from './MicOff';
import MicOn from './MicOn';
import Minus from './Minus';
import Notifications from './Notifications';
import OpenInNew from './OpenInNew';
import Participants from './Participants';
import Pause from './Pause';
import PersonRemove from './PersonRemove';
import Phone from './Phone';
import PhotoCamera from './PhotoCamera';
import PictureInPicture from './PictureInPicture';
import PlayArrow from './PlayArrow';
import Preview from './Preview';
import QrCode from './QrCode';
import Questions from './Questions';
import Rating from './Rating';
import RatingFilled from './RatingFilled';
import Replay from './Replay';
import Replay10 from './Replay10';
import Replay30 from './Replay30';
import Replay5 from './Replay5';
import Schedule from './Schedule';
import Search from './Search';
import Send from './Send';
import SentimentSatisfied from './SentimentSatisfied';
import Settings from './Settings';
import SignalCellular1 from './SignalCellular1';
import SignalCellular2 from './SignalCellular2';
import SignalCellular3 from './SignalCellular3';
import ScreenShare from './ScreenShare';
import StopScreenShare from './StopScreenShare';
import SkipNext from './SkipNext';
import SkipPrevious from './SkipPrevious';
import Smartphone from './Smartphone';
import Subtitles from './Subtitles';
import Superfan from './Superfan';
import Ticket from './Ticket';
import TicketFilled from './TicketFilled';
import Upload from './Upload';
import Verified from './Verified';
import VerifiedInverse from './VerifiedInverse';
import VideoLibrary from './VideoLibrary';
import VideocamOff from './VideocamOff';
import VideocamOn from './VideocamOn';
import VisibilityOff from './VisibilityOff';
import VisibilityOn from './VisibilityOn';
import VolumeDown from './VolumeDown';
import VolumeMute from './VolumeMute';
import VolumeOff from './VolumeOff';
import VolumeUp from './VolumeUp';
import Warning from './Warning';
export type IconNames =
  | 'Apple'
  | 'Facebook'
  | 'Instagram'
  | 'LinkedIn'
  | 'Playstore'
  | 'TikTok'
  | 'Twitter'
  | 'Windows'
  | 'YouTube'
  | 'Account'
  | 'AccountBalanceWallet'
  | 'Add'
  | 'AddAPhoto'
  | 'AddReaction'
  | 'Alarm'
  | 'AndroidFlipCamera'
  | 'AndroidMore'
  | 'AndroidShare'
  | 'ArrowDownward'
  | 'ArrowDropDown'
  | 'ArrowDropUp'
  | 'ArrowForward'
  | 'ArrowUpward'
  | 'Assessment'
  | 'Calendar'
  | 'Campaign'
  | 'Chat'
  | 'Check'
  | 'CheckCircle'
  | 'ChevronDown'
  | 'ChevronLeft'
  | 'ChevronRight'
  | 'ChevronUp'
  | 'Close'
  | 'ClosedCaptionOff'
  | 'ClosedCaptionOn'
  | 'Connect'
  | 'ContentCopy'
  | 'CreditCard'
  | 'Critical'
  | 'Dashboard'
  | 'Delete'
  | 'Disconnect'
  | 'Download'
  | 'Edit'
  | 'Email'
  | 'EndSession'
  | 'EventSeat'
  | 'Explore'
  | 'ExploreFilled'
  | 'FastForward'
  | 'FastRewind'
  | 'Favorite'
  | 'FavoriteFilled'
  | 'FilterList'
  | 'Flag'
  | 'FormatIndentDecrease'
  | 'FormatIndentIncrease'
  | 'FormatListBulleted'
  | 'FormatListNumbered'
  | 'Forward10'
  | 'Forward30'
  | 'Forward5'
  | 'Fullscreen'
  | 'FullscreenExit'
  | 'GroupRemove'
  | 'HamburgerMenu'
  | 'Help'
  | 'IOSShare'
  | 'Image'
  | 'Info'
  | 'IosArrowBack'
  | 'IosArrowForward'
  | 'IosFlipCamera'
  | 'IosMore'
  | 'Language'
  | 'Link'
  | 'LinkOff'
  | 'Lock'
  | 'Logout'
  | 'Menu'
  | 'MenuOpen'
  | 'MicOff'
  | 'MicOn'
  | 'Minus'
  | 'Notifications'
  | 'OpenInNew'
  | 'Participants'
  | 'Pause'
  | 'PersonRemove'
  | 'Phone'
  | 'PhotoCamera'
  | 'PictureInPicture'
  | 'PlayArrow'
  | 'Preview'
  | 'QrCode'
  | 'Questions'
  | 'Rating'
  | 'RatingFilled'
  | 'Replay'
  | 'Replay10'
  | 'Replay30'
  | 'Replay5'
  | 'Schedule'
  | 'Search'
  | 'Send'
  | 'SentimentSatisfied'
  | 'Settings'
  | 'SignalCellular1'
  | 'SignalCellular2'
  | 'SignalCellular3'
  | 'ScreenShare'
  | 'SkipNext'
  | 'SkipPrevious'
  | 'Smartphone'
  | 'StopScreenShare'
  | 'Subtitles'
  | 'Superfan'
  | 'Ticket'
  | 'TicketFilled'
  | 'Upload'
  | 'Verified'
  | 'VerifiedInverse'
  | 'VideoLibrary'
  | 'VideocamOff'
  | 'VideocamOn'
  | 'VisibilityOff'
  | 'VisibilityOn'
  | 'VolumeDown'
  | 'VolumeMute'
  | 'VolumeOff'
  | 'VolumeUp'
  | 'Warning';
export interface IconTypes {
  Apple: React.FC;
  Facebook: React.FC;
  Instagram: React.FC;
  LinkedIn: React.FC;
  Playstore: React.FC;
  TikTok: React.FC;
  Twitter: React.FC;
  Windows: React.FC;
  YouTube: React.FC;
  Account: React.FC;
  AccountBalanceWallet: React.FC;
  Add: React.FC;
  AddAPhoto: React.FC;
  AddReaction: React.FC;
  Alarm: React.FC;
  AndroidFlipCamera: React.FC;
  AndroidMore: React.FC;
  AndroidShare: React.FC;
  ArrowDownward: React.FC;
  ArrowDropDown: React.FC;
  ArrowDropUp: React.FC;
  ArrowForward: React.FC;
  ArrowUpward: React.FC;
  Assessment: React.FC;
  Calendar: React.FC;
  Campaign: React.FC;
  Chat: React.FC;
  Check: React.FC;
  CheckCircle: React.FC;
  ChevronDown: React.FC;
  ChevronLeft: React.FC;
  ChevronRight: React.FC;
  ChevronUp: React.FC;
  Close: React.FC;
  ClosedCaptionOff: React.FC;
  ClosedCaptionOn: React.FC;
  Connect: React.FC;
  ContentCopy: React.FC;
  CreditCard: React.FC;
  Critical: React.FC;
  Dashboard: React.FC;
  Delete: React.FC;
  Disconnect: React.FC;
  Download: React.FC;
  Edit: React.FC;
  Email: React.FC;
  EndSession: React.FC;
  EventSeat: React.FC;
  Explore: React.FC;
  ExploreFilled: React.FC;
  FastForward: React.FC;
  FastRewind: React.FC;
  Favorite: React.FC;
  FavoriteFilled: React.FC;
  FilterList: React.FC;
  Flag: React.FC;
  FormatIndentDecrease: React.FC;
  FormatIndentIncrease: React.FC;
  FormatListBulleted: React.FC;
  FormatListNumbered: React.FC;
  Forward10: React.FC;
  Forward30: React.FC;
  Forward5: React.FC;
  Fullscreen: React.FC;
  FullscreenExit: React.FC;
  GroupRemove: React.FC;
  HamburgerMenu: React.FC;
  Help: React.FC;
  IOSShare: React.FC;
  Image: React.FC;
  Info: React.FC;
  IosArrowBack: React.FC;
  IosArrowForward: React.FC;
  IosFlipCamera: React.FC;
  IosMore: React.FC;
  Language: React.FC;
  Link: React.FC;
  LinkOff: React.FC;
  Lock: React.FC;
  Logout: React.FC;
  Menu: React.FC;
  MenuOpen: React.FC;
  MicOff: React.FC;
  MicOn: React.FC;
  Minus: React.FC;
  Notifications: React.FC;
  OpenInNew: React.FC;
  Participants: React.FC;
  Pause: React.FC;
  PersonRemove: React.FC;
  Phone: React.FC;
  PhotoCamera: React.FC;
  PictureInPicture: React.FC;
  PlayArrow: React.FC;
  Preview: React.FC;
  QrCode: React.FC;
  Questions: React.FC;
  Rating: React.FC;
  RatingFilled: React.FC;
  Replay: React.FC;
  Replay10: React.FC;
  Replay30: React.FC;
  Replay5: React.FC;
  Schedule: React.FC;
  Search: React.FC;
  Send: React.FC;
  SentimentSatisfied: React.FC;
  Settings: React.FC;
  SignalCellular1: React.FC;
  SignalCellular2: React.FC;
  SignalCellular3: React.FC;
  ScreenShare: React.FC;
  StopScreenShare: React.FC;
  SkipNext: React.FC;
  SkipPrevious: React.FC;
  Smartphone: React.FC;
  Subtitles: React.FC;
  Superfan: React.FC;
  Ticket: React.FC;
  TicketFilled: React.FC;
  Upload: React.FC;
  Verified: React.FC;
  VerifiedInverse: React.FC;
  VideoLibrary: React.FC;
  VideocamOff: React.FC;
  VideocamOn: React.FC;
  VisibilityOff: React.FC;
  VisibilityOn: React.FC;
  VolumeDown: React.FC;
  VolumeMute: React.FC;
  VolumeOff: React.FC;
  VolumeUp: React.FC;
  Warning: React.FC;
}
const icons: IconTypes = {
  Apple: Apple,
  Facebook: Facebook,
  Instagram: Instagram,
  LinkedIn: LinkedIn,
  Playstore: Playstore,
  TikTok: TikTok,
  Twitter: Twitter,
  Windows: Windows,
  YouTube: YouTube,
  Account: Account,
  AccountBalanceWallet: AccountBalanceWallet,
  Add: Add,
  AddAPhoto: AddAPhoto,
  AddReaction: AddReaction,
  Alarm: Alarm,
  AndroidFlipCamera: AndroidFlipCamera,
  AndroidMore: AndroidMore,
  AndroidShare: AndroidShare,
  ArrowDownward: ArrowDownward,
  ArrowDropDown: ArrowDropDown,
  ArrowDropUp: ArrowDropUp,
  ArrowForward: ArrowForward,
  ArrowUpward: ArrowUpward,
  Assessment: Assessment,
  Calendar: Calendar,
  Campaign: Campaign,
  Chat: Chat,
  Check: Check,
  CheckCircle: CheckCircle,
  ChevronDown: ChevronDown,
  ChevronLeft: ChevronLeft,
  ChevronRight: ChevronRight,
  ChevronUp: ChevronUp,
  Close: Close,
  ClosedCaptionOff: ClosedCaptionOff,
  ClosedCaptionOn: ClosedCaptionOn,
  Connect: Connect,
  ContentCopy: ContentCopy,
  CreditCard: CreditCard,
  Critical: Critical,
  Dashboard: Dashboard,
  Delete: Delete,
  Disconnect: Disconnect,
  Download: Download,
  Edit: Edit,
  Email: Email,
  EndSession: EndSession,
  EventSeat: EventSeat,
  Explore: Explore,
  ExploreFilled: ExploreFilled,
  FastForward: FastForward,
  FastRewind: FastRewind,
  Favorite: Favorite,
  FavoriteFilled: FavoriteFilled,
  FilterList: FilterList,
  Flag: Flag,
  FormatIndentDecrease: FormatIndentDecrease,
  FormatIndentIncrease: FormatIndentIncrease,
  FormatListBulleted: FormatListBulleted,
  FormatListNumbered: FormatListNumbered,
  Forward10: Forward10,
  Forward30: Forward30,
  Forward5: Forward5,
  Fullscreen: Fullscreen,
  FullscreenExit: FullscreenExit,
  GroupRemove: GroupRemove,
  HamburgerMenu: HamburgerMenu,
  Help: Help,
  IOSShare: IOSShare,
  Image: Image,
  Info: Info,
  IosArrowBack: IosArrowBack,
  IosArrowForward: IosArrowForward,
  IosFlipCamera: IosFlipCamera,
  IosMore: IosMore,
  Language: Language,
  Link: Link,
  LinkOff: LinkOff,
  Lock: Lock,
  Logout: Logout,
  Menu: Menu,
  MenuOpen: MenuOpen,
  MicOff: MicOff,
  MicOn: MicOn,
  Minus: Minus,
  Notifications: Notifications,
  OpenInNew: OpenInNew,
  Participants: Participants,
  Pause: Pause,
  PersonRemove: PersonRemove,
  Phone: Phone,
  PhotoCamera: PhotoCamera,
  PictureInPicture: PictureInPicture,
  PlayArrow: PlayArrow,
  Preview: Preview,
  QrCode: QrCode,
  Questions: Questions,
  Rating: Rating,
  RatingFilled: RatingFilled,
  Replay: Replay,
  Replay10: Replay10,
  Replay30: Replay30,
  Replay5: Replay5,
  Schedule: Schedule,
  Search: Search,
  Send: Send,
  SentimentSatisfied: SentimentSatisfied,
  Settings: Settings,
  SignalCellular1: SignalCellular1,
  SignalCellular2: SignalCellular2,
  SignalCellular3: SignalCellular3,
  ScreenShare: ScreenShare,
  SkipNext: SkipNext,
  SkipPrevious: SkipPrevious,
  Smartphone: Smartphone,
  StopScreenShare: StopScreenShare,
  Subtitles: Subtitles,
  Superfan: Superfan,
  Ticket: Ticket,
  TicketFilled: TicketFilled,
  Upload: Upload,
  Verified: Verified,
  VerifiedInverse: VerifiedInverse,
  VideoLibrary: VideoLibrary,
  VideocamOff: VideocamOff,
  VideocamOn: VideocamOn,
  VisibilityOff: VisibilityOff,
  VisibilityOn: VisibilityOn,
  VolumeDown: VolumeDown,
  VolumeMute: VolumeMute,
  VolumeOff: VolumeOff,
  VolumeUp: VolumeUp,
  Warning: Warning,
};

export default icons;
