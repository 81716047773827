import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const Connect = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M13 8c0-2.21-1.79-4-4-4S5 5.79 5 8s1.79 4 4 4 4-1.79 4-4Zm2 2v2h3v3h2v-3h3v-2h-3V7h-2v3h-3ZM1 18v2h16v-2c0-2.66-5.33-4-8-4s-8 1.34-8 4Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(Connect);
