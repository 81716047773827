import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const SignalCellular3 = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M17 4h3v16h-3V4ZM5 14h3v6H5v-6Zm6-5h3v11h-3V9Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(SignalCellular3);
