import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const LinkOff = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 3v3h2V3h-2Zm3 6h3V7h-3v2Zm-1.753.176-1.414 1.415 2.829 2.828a3.009 3.009 0 0 1 0 4.243 3.009 3.009 0 0 1-4.243 0l-2.829-2.829-1.414 1.414 2.829 2.829a5.002 5.002 0 0 0 7.07 0 5.002 5.002 0 0 0 0-7.071l-2.828-2.829Zm-7.07 4.243-2.83-2.828a3.009 3.009 0 0 1 0-4.243 3.009 3.009 0 0 1 4.244 0l2.828 2.828 1.414-1.414-2.828-2.828a5.002 5.002 0 0 0-7.071 0 5.002 5.002 0 0 0 0 7.07l2.828 2.83 1.414-1.415ZM9 18v3H7v-3h2Zm-6-3h3v2H3v-2Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(LinkOff);
