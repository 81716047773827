import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const Participants = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3Zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3Zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5Zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(Participants);
