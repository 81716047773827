import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const AndroidFlipCamera = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 10V8H5.09C6.47 5.61 9.05 4 12 4c3.72 0 6.85 2.56 7.74 6h2.06c-.93-4.56-4.96-8-9.8-8-3.27 0-6.18 1.58-8 4.01V4H2v6h6Zm1 2c0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3-3 1.34-3 3Zm9.91 4H16v-2h6v6h-2v-2.01A9.985 9.985 0 0 1 12 22c-4.84 0-8.87-3.44-9.8-8h2.06c.89 3.44 4.02 6 7.74 6 2.95 0 5.53-1.61 6.91-4Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(AndroidFlipCamera);
