import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const Questions = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <g clipPath="url(#prefix__a)">
      <path
        d="M16.49 22.12a7.442 7.442 0 0 0 4.28-7.168l-.439-7.634a.934.934 0 0 0-1.57-.628l-.566.532a1.874 1.874 0 0 0-.591 1.47l.2 3.544-.635.296-4.636-9.942a1.167 1.167 0 0 0-2.115.986l3.452 7.404-.846.394-4.242-9.096a1.167 1.167 0 0 0-2.115.987l4.241 9.096-.846.394-3.65-7.826a1.167 1.167 0 0 0-2.115.986l3.65 7.827-.846.394-2.466-5.288a1.167 1.167 0 0 0-2.116.986l4.045 8.673a7.467 7.467 0 0 0 9.925 3.613Z"
        fill={theme.contentColor[contentColor]}
      />
    </g>
    <defs>
      <clipPath id="prefix__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default withTheme(Questions);
