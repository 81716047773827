import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const OpenInNew = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7ZM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(OpenInNew);
