import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const Replay30 = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8Zm-2.44 8.49h.45c.21 0 .37-.05.48-.16.11-.11.16-.25.16-.43a.538.538 0 0 0-.15-.39.418.418 0 0 0-.18-.11.935.935 0 0 0-.25-.04.78.78 0 0 0-.22.03.4.4 0 0 0-.18.1c-.05.05-.09.09-.12.15s-.05.13-.05.2h-.85a1.06 1.06 0 0 1 .41-.85c.13-.1.27-.18.44-.23.17-.05.35-.08.54-.08.21 0 .41.03.59.08s.33.13.46.23.23.23.3.38c.07.15.11.33.11.53a.842.842 0 0 1-.17.52 1.1 1.1 0 0 1-.48.39c.24.09.42.21.54.39.12.18.18.38.18.61 0 .2-.04.38-.12.53a1.1 1.1 0 0 1-.32.39c-.14.1-.29.19-.48.24-.19.05-.38.08-.6.08-.18 0-.36-.02-.53-.07-.17-.05-.33-.12-.46-.23-.13-.11-.25-.23-.33-.38-.08-.15-.12-.34-.12-.55h.85c0 .08.02.15.05.22.03.07.07.12.13.17.06.05.12.09.2.11.08.02.16.04.25.04.1 0 .19-.01.27-.04a.561.561 0 0 0 .33-.3c.03-.07.04-.15.04-.24 0-.11-.02-.21-.05-.29a.508.508 0 0 0-.36-.31c-.09-.02-.18-.04-.29-.04h-.47v-.65Zm5.64 1.57c.07-.22.1-.5.1-.82v-.74c0-.32-.04-.59-.11-.82-.07-.23-.17-.42-.3-.57-.13-.15-.28-.26-.46-.33s-.37-.1-.59-.1c-.22 0-.42.03-.59.1-.17.07-.33.18-.45.33-.12.15-.22.35-.29.57-.07.22-.1.5-.1.82v.74c0 .32.04.59.11.82.07.23.17.42.3.57.13.15.28.26.46.33s.37.1.59.1c.22 0 .42-.03.59-.1.17-.07.33-.18.45-.33.12-.15.22-.35.29-.57Zm-.79-2.16c.03.13.04.29.04.48h-.01v.97c0 .19-.01.35-.04.48-.02.13-.06.24-.11.32-.05.08-.12.14-.19.17a.655.655 0 0 1-.5 0 .389.389 0 0 1-.19-.17c-.05-.08-.09-.19-.12-.32s-.04-.29-.04-.48v-.97c0-.19.01-.35.04-.48s.07-.23.12-.31c.05-.08.12-.14.19-.17a.655.655 0 0 1 .5 0c.08.03.14.09.19.17.05.08.09.18.12.31Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(Replay30);
