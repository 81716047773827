import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const Forward30 = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 13c0 3.31-2.69 6-6 6s-6-2.69-6-6 2.69-6 6-6v4l5-5-5-5v4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8h-2Zm-7.94 2.38c-.29 0-.62-.17-.62-.54h-.85c0 .97.9 1.23 1.45 1.23.87 0 1.51-.46 1.51-1.25 0-.66-.45-.9-.71-1 .11-.05.65-.32.65-.92 0-.21-.05-1.22-1.44-1.22-.62 0-1.4.35-1.4 1.16h.85c0-.34.31-.48.57-.48.582 0 .58.486.58.538v.002c0 .52-.41.59-.63.59h-.46v.66h.45c.65 0 .7.42.7.64 0 .32-.21.59-.65.59Zm3.773-3.7h.035c.21-.004 1.432-.025 1.422 1.82v.74c0 1.845-1.213 1.824-1.422 1.82h-.033c-.195.003-1.425.021-1.425-1.82v-.74c0-1.84 1.22-1.823 1.423-1.82Zm.027 3.7c.38 0 .59-.26.59-1.03v-.97c0-.75-.22-1.01-.6-1.01-.37 0-.59.26-.59 1.01v.97c0 .77.22 1.03.6 1.03Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(Forward30);
