import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const Replay10 = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.99 5V1l-5 5 5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6h-2c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8Zm-1.1 11h-.85v-3.26l-1.01.31v-.69l1.77-.63h.09V16Zm4.18-.94c.07-.22.1-.5.1-.82v-.74c0-.32-.04-.59-.11-.82-.07-.23-.17-.42-.3-.57-.13-.15-.28-.26-.46-.33s-.37-.1-.59-.1c-.22 0-.42.03-.59.1-.17.07-.33.18-.45.33-.12.15-.22.35-.29.57-.07.22-.1.5-.1.82v.74c0 .32.04.59.11.82.07.23.17.42.3.57.13.15.28.26.46.33s.37.1.59.1c.22 0 .42-.03.59-.1.17-.07.33-.18.45-.33.12-.15.22-.35.29-.57Zm-.79-2.16c.03.13.04.29.04.48h-.01v.97c0 .19-.02.35-.04.48s-.06.24-.11.32c-.05.08-.12.14-.19.17a.655.655 0 0 1-.5 0 .389.389 0 0 1-.19-.17c-.05-.08-.09-.19-.12-.32s-.04-.29-.04-.48v-.97c0-.19.01-.35.04-.48s.07-.23.12-.31c.05-.08.12-.14.19-.17a.655.655 0 0 1 .5 0c.08.03.14.09.19.17.05.08.09.18.12.31Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(Replay10);
