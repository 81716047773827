import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const Forward5 = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 19c3.31 0 6-2.69 6-6h2c0 4.42-3.58 8-8 8s-8-3.58-8-8 3.58-8 8-8V1l5 5-5 5V7c-3.31 0-6 2.69-6 6s2.69 6 6 6Zm-.57-4.18c.02.25.16.56.6.56.559 0 .59-.601.598-.756l.002-.034c0-.52-.29-.75-.69-.75-.29 0-.44.1-.57.23l-.67-.17.25-2.17h2.39v.71h-1.7l-.11.92c.21-.11.44-.16.64-.16.33 0 1.3.11 1.3 1.44 0 .8-.51 1.43-1.44 1.43-.65 0-1.41-.4-1.44-1.25h.84Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(Forward5);
