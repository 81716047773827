import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const QrCode = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M3 11h8V3H3v8Zm2-6h4v4H5V5ZM3 21h8v-8H3v8Zm2-6h4v4H5v-4ZM13 3v8h8V3h-8Zm6 6h-4V5h4v4ZM21 19h-2v2h2v-2ZM15 13h-2v2h2v-2ZM17 15h-2v2h2v-2ZM15 17h-2v2h2v-2ZM17 19h-2v2h2v-2ZM19 17h-2v2h2v-2ZM19 13h-2v2h2v-2ZM21 15h-2v2h2v-2Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(QrCode);
