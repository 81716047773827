import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const Verified = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill={theme.contentColor[contentColor]} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.703 8.01a1.5 1.5 0 0 1 0 2.122l-6.857 6.857a1.5 1.5 0 0 1-2.121 0l-4.286-4.286a1.5 1.5 0 1 1 2.122-2.12l3.225 3.224 5.796-5.796a1.5 1.5 0 0 1 2.121 0Z"
      fill="#fff"
    />
  </svg>
);

export default withTheme(Verified);
