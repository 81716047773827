import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const AccountBalanceWallet = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M21 18v1c0 1.1-.9 2-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14c1.1 0 2 .9 2 2v1h-9a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9Zm-9-2h10V8H12v8Zm4-2.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(AccountBalanceWallet);
