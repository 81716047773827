import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const Replay5 = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1v4c4.42 0 8 3.58 8 8s-3.58 8-8 8-8-3.58-8-8h2c0 3.31 2.69 6 6 6s6-2.69 6-6-2.69-6-6-6v4L7 6l5-5Zm-1.06 10.73-.25 2.17.67.16a1.03 1.03 0 0 0 .022-.023.29.29 0 0 1 .078-.067c.02-.01.04-.023.06-.035a.66.66 0 0 1 .21-.085c.05-.01.12-.02.2-.02.11 0 .22.02.3.05.08.03.15.08.21.15.06.07.1.14.13.24.03.1.04.2.04.31 0 .11 0 .21-.03.31s-.07.18-.11.25a.49.49 0 0 1-.45.23.65.65 0 0 1-.42-.15c-.11-.09-.17-.23-.19-.41h-.84c0 .2.05.38.13.53.08.15.18.29.32.39.14.1.29.19.46.24.17.05.35.08.53.08.25 0 .46-.05.64-.12.18-.07.33-.18.45-.31s.21-.28.27-.45c.06-.17.09-.35.09-.54 0-.22-.04-.42-.09-.6-.05-.18-.14-.33-.25-.45-.11-.12-.25-.21-.41-.28a1.35 1.35 0 0 0-.84-.06c-.03.008-.06.015-.09.02-.06.01-.11.03-.15.05-.017.008-.033.015-.05.021-.022.01-.043.017-.06.029l.11-.92h1.7v-.71h-2.39Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(Replay5);
