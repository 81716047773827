import React, {SVGProps} from 'react';
import {withTheme, DefaultTheme} from 'styled-components';
interface IconProps extends SVGProps<SVGSVGElement> {
  size?: string | number;
  contentColor?: string;
  theme: DefaultTheme;
}
const Link = ({
  size = 24,
  contentColor = 'contentDefault',
  theme,
  ...props
}: IconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="m19.076 12.005-2.829-2.829-1.414 1.415 2.829 2.828a3.009 3.009 0 0 1 0 4.243 3.009 3.009 0 0 1-4.243 0l-2.829-2.829-1.414 1.414 2.829 2.829a5.002 5.002 0 0 0 7.07 0 5.002 5.002 0 0 0 0-7.071Zm-9.9 1.414-2.828-2.828a3.009 3.009 0 0 1 0-4.243 3.009 3.009 0 0 1 4.243 0l2.828 2.828 1.414-1.414-2.828-2.828a5.002 5.002 0 0 0-7.071 0 5.002 5.002 0 0 0 0 7.07l2.828 2.83 1.414-1.415Zm.707-4.95 5.657 5.657-1.414 1.414L8.47 9.883 9.883 8.47Z"
      fill={theme.contentColor[contentColor]}
    />
  </svg>
);

export default withTheme(Link);
